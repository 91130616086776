import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Image from "next/image";
import { Button } from "antd";
import moment from "moment";
import ConvertToEn from "utils/convertToEn";
import Link from "next/link";
import imgLoader from "utils/nextImageLoader";
import { MdOutlineDateRange } from "react-icons/md";
import { Col, Row } from "react-bootstrap";

const HaberItem = ({ image, title, description, id, date, href }) => {
  return (
    <div style={{ marginBottom: "15px" }}>
      <Card style={{ width: "100%" }}>
        <Card.Header>
          <div className="img">
            <Image
              loader={imgLoader}
              src={`model=Content&fileName=${image}`}
              layout="fill"
            />
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <Link href={href}>
              <a>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
              </a>
            </Link>
          </div>
          <div className="fotter-card">
            <div className="haber-detay">
              <Link href={href}>
                <Button className="btn">Haber Detay</Button>
              </Link>
            </div>
            <div className="tarih-etiketi">
              <MdOutlineDateRange className="icon" />
              {moment(date).format("Do MMMM YYYY")}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const Haberler = ({ data }) => {
  const list = data?.sections[0]?.categories[0];
  const settings = {
    dots: true,
    infinite: list?.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const size = useWindowSize();
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      if (typeof window !== "undefined") {
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
      }
    }, []);
    return windowSize;
  }

  return (
    <div id="haberler">
      <div className="haberler">
        <div>
          <h1>VİRANŞEHİR HABERLER</h1>
        </div>
        <Container>
          {size.width < 992 && (
            <Slider {...settings} className="haberler-slider">
              {list?.contents?.reverse()?.map((item, i) => (
                <HaberItem
                  href={`${ConvertToEn(list.text)}-${list.id}/${item.id}-Detay`}
                  title={item?.title}
                  description={item?.description}
                  date={item?.editDate}
                  image={item?.imageName}
                  key={i}
                />
              ))}
            </Slider>
          )}
          {size.width >= 992 && (
            <Row className="haberler-slider">
              {list?.contents?.slice(0, 9).map((item, i) => (
                <Col xxl={4} lg={4} md={6} xs={12} key={i}>
                  <HaberItem
                    href={`${ConvertToEn(list.text)}-${list.id}/${
                      item.id
                    }-Detay`}
                    title={item?.title}
                    description={item?.description}
                    date={item?.editDate}
                    image={item?.imageName}
                  />
                </Col>
              ))}
            </Row>
          )}
          <div className="tum-btn">
            <div className="btn">
              <Link
                href={`/${ConvertToEn(
                  data["sections"][0].categories[0].text
                )}-${data["sections"][0].categories[0].id}`}
              >
                <Button className="tum-haberler-btn">TÜM HABERLER</Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Haberler;
