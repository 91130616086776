function ConvertToEn(text) {
    if(!text ||typeof text != "string") return "";
    var Maps = {
        "İ": "I", "Ş": "S", "Ç": "C", "Ğ": "G", "Ü": "U", "Ö": "O",
        "ı": "i", "ş": "s", "ç": "c", "ğ": "g", "ü": "u", "ö": "o"
    };
    Object.keys(Maps).forEach(function (Old) {
        text = text.replace(new RegExp(Old, 'g'), Maps[Old]);
    });
    text = text.replace(/ /g , "");
    return text;
}


export default ConvertToEn;